export default [
  {
    header: 'СК',
    permissions: ['employee', 'rester_employee'],
  },
  {
    title: 'Действия',
    route: 'actions-home',
    icon: 'SlidersIcon',
    permissions: ['employee', 'rester_employee'],
  },
  {
    title: 'Все дела',
    route: 'cases-home',
    icon: 'ClipboardIcon',
    permissions: ['employee', 'rester_employee'],
  },
  {
    title: 'Архив',
    route: 'archive-home',
    icon: 'ArchiveIcon',
    permissions: ['employee', 'rester_employee'],
  },
  {
    title: 'Рабочее место',
    route: 'workplace-home',
    icon: 'BriefcaseIcon',
    permissions: ['employee', 'rester_employee'],
  },
  {
    title: 'Юр. лицо',
    route: 'sk-entity',
    icon: 'UsersIcon',
    permissions: ['employee', 'rester_employee'],
  },
  {
    header: 'ДК',
    permissions: ['employee', 'rester_employee', 'dk_operator'],
  },
  {
    title: 'Список дел',
    route: 'todo-list',
    icon: 'SlidersIcon',
    permissions: ['dk_operator'],
    ignore: true,
  },
  {
    title: 'Портфели',
    route: 'portfolios',
    icon: 'BatteryIcon',
    permissions: ['employee', 'rester_employee', 'dk_manager'],
  },
  {
    title: 'Добрый коллектор',
    route: 'dk',
    icon: 'PhoneCallIcon',
    permissions: ['employee', 'rester_employee', 'dk_operator', 'dk_manager'],
  },
  {
    title: 'История звонков',
    route: 'call-history',
    icon: 'PhoneCallIcon',
    permissions: ['employee', 'employee', 'dk_manager'],
  },
  {
    title: 'Не перспективные',
    route: 'not_prospective',
    icon: 'UserXIcon',
    permissions: ['dk_operator', 'dk_manager'],
  },
  // {
  //   title: 'Список дел',
  //   route: 'todo-list',
  //   icon: 'SlidersIcon',
  //   permissions: ['admin'],
  // },

  {
    header: 'ДК Юр. лица',
    permissions: ['employee', 'rester_employee', 'dk_operator'],
  },
  {
    title: 'Портфели Юр. лица',
    route: 'legal-portfolios',
    icon: 'BatteryIcon',
    permissions: ['employee', 'rester_employee', 'dk_manager'],
  },
  {
    title: 'ДК Юр лица',
    route: 'dk-legal-face',
    icon: 'PhoneCallIcon',
    permissions: ['employee', 'rester_employee', 'dk_operator', 'dk_manager'],
  },

  {
    header: 'ЭДО',
    permissions: ['employee', 'rester_employee', 'dk_operator', 'dk_manager'],
  },
  {
    title: 'Управление файлами',
    route: 'file-management',
    icon: 'FileIcon',
    permissions: ['employee', 'rester_employee', 'dk_operator', 'dk_manager'],
  },

  {
    header: 'Администрирование',
    permissions: ['rester_employee', 'dk_manager'],
  },
  {
    title: 'Пользователи',
    route: 'users-home',
    icon: 'UsersIcon',
    permissions: ['dk_manager'],
  },
  {
    title: 'Роли',
    route: 'roles-home',
    icon: 'UserPlusIcon',
  },
  {
    title: 'Импорт реестров',
    route: 'import-registries',
    icon: 'DownloadIcon',
    permissions: ['admin', 'rester_employee', 'dk_manager'],
  },
  {
    title: 'Справочник',
    route: 'reference',
    icon: 'SettingsIcon',
  },
  {
    header: 'Конфигурация',
    permissions: ['dk_manager'],
  },
  {
    title: 'Настройка шаблона',
    route: 'actions-settings',
    icon: 'CodepenIcon',
    permissions: ['dk_manager'],
  },
  {
    title: 'Настройка действий',
    route: 'process-settings',
    icon: 'CodepenIcon',
    permissions: ['dk_manager'],
  },
  {
    header: 'Статистика ',
  },
  {
    title: 'Статистика',
    route: 'statistics-home',
    icon: 'TrendingUpIcon',
  },
]
