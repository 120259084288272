<template>
  <ul style="background: rgb(40, 48, 70)">
    <template v-for="item in items">
      <component
        :is="resolveNavItemComponent(item)"
        v-if="checkPerm(item.permissions, item)"
        :key="item.header || item.title"
        :item="item"
      />
    </template>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import useJwt from '@/auth/jwt/useJwt'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  methods: {
    checkPerm(permissions, item) {
      const useData = useJwt.getUserData()
      let out
      if (useData && useData.is_superuser) {
        if (item.ignore) {
          return false
        }

        return true
      }

      if (!permissions) {
        return false
      }

      for (let i = 0; i < permissions.length; i += 1) {
        const element = permissions[i]
        if (element === 'home_all' || useData.permissions.includes(element)) {
          out = true
          break
        }
      }
      return out
    },
  },
}
</script>
